import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import isMobileDevice from "../utils/isMobileDevice";
import SEO from "../components/seo";
import Menu from "../components/shared/menu";
import RegisterForm from "../components/register/register-form";
import Footer from "../components/shared/footer";
import styles from "../styles/register/register.module.scss";

const SuccessPage = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        let local = localStorage.getItem("age");
        let session = sessionStorage.getItem("age");
        if (!session && !local)
            navigate("/age-gate", { state: { newPath: "/" } });
    }, []);

    useEffect(() => {
        window.dataLayer.push({
          brand: "Goose Island",
          Zone: "SAZ",
          country: "ARG",
          city: "CABA",
          campaign: `${
            window.location.search.includes("?cmp=")
              ? window.location.search.split("?cmp=")[1].split("&")[0]
              : ""
          }`,
          cms: "0",
          event: "pageView",
          language: "es",
          login: false,
          pageName: "Success",
          pageType: "Basic page",
          SiteType: "Brand",
          product: "NA",
          sku: "NA",
          userUid: "",
          url: "https://www.gooseisland.com.ar/success",
          url_campaign: `${
            window.location.search.includes("utm_campaign=")
              ? window.location.search.split("utm_campaign=")[1]
              : ""
          }`,
          Step_number:"",
        })
      }, [])

    useEffect(() => {
        setIsMobile(isMobileDevice());
        window.dotq = window.dotq || [];
        window.dotq.push({
            projectId: "10000",
            properties: {
                pixelId: "10187159",
                qstrings: {
                    et: "custom",
                    ea: "success",
                },
            },
        });
    }, []);

    return (
        <div className={styles.registerContainer}>
            <SEO title="Goose Island" />
            <Menu
                isMobile={isMobile}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
            />
            <RegisterForm
                isMobile={isMobile}
                success={true}
                setShowMenu={setShowMenu}
            />
            <Footer isMobile={isMobile} />
        </div>
    );
};

export default SuccessPage;
